.upload-questions-wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.upload-questions-wrapper .file-name {
    margin: 1rem 0;
    padding: .6rem 1rem;

    font-weight: bold;

    box-shadow: 0 0 8px #c4c3c3;
    border-radius: 1px solid #b1b0b0;
}

.upload-questions-wrapper .btn-outline {
    font-size: 16px;
    padding: 5px 15px;
}

.upload-questions-wrapper .btn-outline.upload-btn.selected {
    display: none;
}