.questions-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.questions-grid {
    width: 100%;
    margin: .5rem 0;
    background: white;
    border: 1px solid #c3c4c7;
    border-spacing: 0;
}

.questions-grid thead th {
    font-weight: 400;    
    padding: 8px 10px;
    color: var(--primary-font);
    border-bottom: 1px solid #c3c4c7;
}

.questions-grid tr:nth-child(even) td{
    background: white;
}
.questions-grid tr:nth-child(odd) td{
    background: #F9F9F9;
}

.questions-grid tr.no-items-found {
    width: 100%;
    background: #F9F9F9;
}

.questions-grid tbody tr {
    position: relative;
}

.questions-grid tbody tr td {
    position: relative;
    width: calc(100% - 20px);
    height: 100%;
    border: none;
    font-size: 14px;
    padding: 22px 10px;
    background: transparent;
    text-align: center;
}

.grid-options.shown {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
}

.grid-options {
    width: 150px;
    display: none;

    position: absolute;
    left: 0;
    bottom: 0;

    color: var(--primary);
    font-weight: 400;
    background: transparent !important;
}

.grid-option-btn {
    background: transparent;
    color: var(--primary);
    border: none;
    cursor: pointer;
}

.grid-option-btn:hover {
    color: #135e96;
}

.grid-option-btn.delete {
    color: #b32d2e;
}

.no-items-found {
    width: 100%;
    display: flex;
    column-width: 100%;
    justify-content: center;
}

.no-items-found td {
    width: 100%;
    padding: 8px 10px;
    text-align: center;
    background: #F9F9F9 !important;
}

.question-img {
    width: 2rem;
}

.btn-outline.btn-import {
    margin-right: .5rem;
}