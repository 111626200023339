:root {
    --background: #F0F0F1;
    --primary: #135E96;

    --primary-font: #1D2327;

    --input-fontcolor: #2c3338;

    --error: #ff00008f;
}

* {
   margin: 0;
   list-style: none;
   box-sizing: border-box;

   -webkit-user-select: none;
   -moz-user-select: none;
   user-select: none;
}

html, body, #root {
    height: 100vh;
}

button {
    cursor: pointer;
}

button:disabled {
    opacity: .7;
}

button.loading {
    cursor: progress;
}

input[type=text], input[type=number], input[type=email], textarea {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    -webkit-user-select: text;
    -moz-user-select: text;
    user-select: text;
}

textarea {
    resize: none;
}

.pointer {
    cursor: pointer;
}

.check-icon {
    background-image: url(../img/fi-check-solid.svg);
}

.check-icon:hover {
    background-image: url(../img/fi-xmark-solid.svg);
}

.xmark-icon {
    background-image: url(../img/fi-xmark-solid.svg);
}

.xmark-icon:hover {
    background-image: url(../img/fi-check-solid.svg);
}

.btn-primary {
    display: inline-block;
    text-decoration: none;
    font-size: 13px;
    line-height: 2.15384615;
    min-height: 30px;
    margin: 0;
    padding: 0 10px;
    cursor: pointer;
    border-radius: 3px;
    border: none; 
    white-space: nowrap;
    box-sizing: border-box;

    color: white;
    background: var(--primary);
}

.btn-outline {
    display: inline-block;
    text-decoration: none;
    font-size: 13px;
    line-height: 2.15384615;
    min-height: 30px;
    margin: 0;
    padding: 0 10px;
    cursor: pointer;
    border-radius: 3px;
    border: 1px solid var(--primary);
    white-space: nowrap;
    box-sizing: border-box;

    color: var(--primary);
    background: var(--background);
}

.btn-outline:disabled {
    cursor: not-allowed;
    opacity: .7;
}

.btn-outline:hover {
    background: #e6e6e6;
}

.btn-outline.cancel {
    border: 1px solid var(--error);
    color: var(--error);
}

.error-message {
    font-weight: 700;
    color: var(--error);
}

.is-invalid {
    border: 1px solid #ff00008f !important;
}

.title-text {
    width: 100%;
    height: 1.7em;
    margin: .5rem;
    margin-left: 0;
    padding: 3px 8px;
    font-size: 1.6em;
    line-height: 100%;
}