.form-group {
    width: 100%;
    height: 100%;
    
    display: flex;
    flex-direction: column;

    background: white;

    margin: 1rem 0;
    border: 1px solid #c3c4c7
}

.group-header {
    border-bottom: 1px solid #c3c4c7;
}

.group-header h2 {
    font-size: 14px;
    padding: 8px 12px;
    margin: 0;
    line-height: 1.4;
}

.group-content {
    padding: 12px 12px;
    line-height: 1.4;
    font-size: 13px;
}