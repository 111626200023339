.checkbox-control {
    margin: .3rem .5rem .7rem;
}

.checkbox-control > * {
    cursor: pointer;
}

.checkbox-control label {
    font-weight: 600;
    font-size: 1rem;
    margin-left: 0.3rem;
}