.content-wrapper {
    width: 100%;
    min-height: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;

    padding: .5rem;
    background: var(--background);
}

.page-header {
    margin: .5rem 0;
}

.signout-button {
    float: right;
}

.page-title {
    display: flex;

    font-size: 23px;
    font-weight: 400;
    color: var(--primary-font);
}