.image-upload-wrapper {
    display: flex;
    position: relative;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    
    width: 700px;
    min-height: 300px;
    padding: 1.5rem 1rem;
    margin: 1rem 1rem 0 0;
    border: 1px solid #0003;
}

.control-name {
    font-size: 1em;
    font-weight: bold;

    display: block;

    top: 5px;
    left: 5px;
    position: absolute;
}

.image-upload-wrapper .file-label {
    cursor: pointer;
    width: fit-content;
}

.image-upload-wrapper .upload-btn {
    font-weight: 400;
    padding: 8px 20px;
}

.image-upload-wrapper .upload-btn.selected {
    display: none;
}

.image-upload-wrapper p {
    margin: .6rem 0;
}

.image-upload-wrapper .error-message {
    position: absolute;
    bottom: 5px;
}


.preview-image {
    background: #fff;
    cursor: pointer;
    max-width: 32rem;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.preview-image.selected {
    min-width: 11rem;
    min-height: 7.5rem;
}