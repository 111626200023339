.form-control {
    display: flex;
    flex-direction: column;

    margin: .5rem 0;
}

.form-control label {
    font-size: 1em;
    font-weight: bold;
    display: block;
}

.form-control input {
    padding: 0 8px;
    line-height: 2;
    font-size: 14px;
    min-height: 30px;
}