.form-input {
    outline: none;
    border-radius: 4px;
    border: 1.5px solid #8c8f94;
    box-shadow: 0 0 0 transparent;
    
    color: var(--input-fontcolor);
    background: white;
}

.form-input:focus {
    border: 2px solid var(--primary);
}