.react-modal-container {
    height: 100%;
    width: 100%;
}

.react-modal-container h1 {
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 2rem;
    margin-bottom: 3rem;
    text-align: center;
}

.react-modal-close-button {
    position: absolute;
    top: 1.45rem;
    right: 1.5rem;
    border: 0;
    background: transparent;
}

.react-modal-content-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.react-modal-content {
    position: relative;
    min-width: 539px;
    padding: 3rem 2.5rem;
    background-color: #fff;
    border-radius: 0.3rem;

    display: flex;
    flex-direction: column;
    align-items: center;

    max-height: 95vh;
    overflow-y: auto;
    z-index: 999;
}

.react-modal-overlay {
    background: rgba(0,0,0,0.5);

    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 998;

    display: flex;
    align-items: center;
    justify-content: center;
}

.react-modal-container .footer {
    width: 100%;
    display: flex;
    justify-content: center;
}

.react-modal-container .footer > button {
    font-size: 1rem;
    padding: 0.2rem 1.2rem;
}

.react-modal-container .footer > button:last-of-type {
    margin-left: 1.5rem;
}