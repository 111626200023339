.toast-wrapper {
    display: flex;
    font-size: 0.95rem;
}

.toast-wrapper button {
    margin-left: 10px;
    padding: 0px;
    display: flex;
    align-items: center;
    border: 0px;
    background-color: transparent;
    color: #63605F;
}